import React from 'react'

import { InputTypes } from '@platform-ui-kit/components-library'
import { WppInput } from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { get, upperFirst, replace } from 'lodash'

export const FormInput: React.FC<
  React.PropsWithChildren<{
    name: string
    label: string
    placeholder: string
    className?: string
    type?: InputTypes
    withPixelsNormalization?: boolean
  }>
> = ({ name, label, placeholder, className, type = 'number', withPixelsNormalization = false, children }) => {
  const formik = useFormikContext()

  const handleBlur = (blurData: any) => {
    if (withPixelsNormalization && get(formik.values, name)) {
      formik.setFieldValue(name, get(formik.values, name) + 'px')
    }

    return formik.handleBlur(blurData)
  }

  const handleFocus = () => {
    if (withPixelsNormalization) {
      formik.setFieldValue(name, replace(get(formik.values, name), 'px', ''))
    }
  }

  const handleChange = (event: CustomEvent) => {
    if (withPixelsNormalization) {
      formik.setFieldValue(name, event.detail.value)

      const newValue = replace(event.detail.value, /[^0-9]/g, '')
      formik.setFieldValue(name, newValue)

      return
    }

    return formik.handleChange(event)
  }

  return (
    <WppInput
      id={name}
      name={name}
      labelConfig={{ text: upperFirst(label) }}
      value={get(formik.values, name)}
      placeholder={placeholder}
      required
      type={withPixelsNormalization ? 'text' : type}
      onWppChange={handleChange}
      className={className}
      onBlur={handleBlur}
      onFocus={handleFocus}
    >
      {children}
    </WppInput>
  )
}
