// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DmV4K{background-color:var(--wpp-white-color)}.DmV4K .rr9Ue{display:flex;align-items:center;margin-right:32px;cursor:pointer}.DmV4K .rr9Ue .MRXLV{margin-left:12px;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,OACE,uCAAA,CAEA,cACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CAEA,qBACE,gBAAA,CACA,kBAAA","sourcesContent":[".topbar {\n  background-color: var(--wpp-white-color);\n\n  .app {\n    display: flex;\n    align-items: center;\n    margin-right: 32px;\n    cursor: pointer;\n\n    .title {\n      margin-left: 12px;\n      white-space: nowrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": `DmV4K`,
	"app": `rr9Ue`,
	"title": `MRXLV`
};
export default ___CSS_LOADER_EXPORT___;
