// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gWXFq .HV2gE{display:flex;justify-content:flex-end}.gWXFq .HV2gE :first-child{margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/CreateNewThemeModal/CreateNewThemeModal.module.scss"],"names":[],"mappings":"AACE,cACE,YAAA,CACA,wBAAA,CAEA,2BACE,iBAAA","sourcesContent":[".modal {\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n\n    :first-child {\n      margin-right: 12px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `gWXFq`,
	"actions": `HV2gE`
};
export default ___CSS_LOADER_EXPORT___;
