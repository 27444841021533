import { createTheme } from '@platform-ui-kit/components-library'

import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import { IAppContext } from '../../app/context'
import { recursiveObjectFilter } from '../../utils'

interface CreateFileInfo {
  blob: Blob
  fileName: string
}

export const createJson = (appContext: IAppContext, name: string): CreateFileInfo => {
  const dataStr = JSON.stringify(getThemeFromCreateThemeInfo(appContext), null, 2)

  const blob = new Blob([dataStr], { type: 'text/plain' })
  const fileName = `${name} theme.json`

  return {
    blob,
    fileName,
  }
}

const createCss = (appContext: IAppContext, name: string): CreateFileInfo => {
  const dataStr = JSON.stringify(
    createTheme(recursiveObjectFilter(getThemeFromCreateThemeInfo(appContext), value => !!value) as any),
    null,
    2,
  )
    .replace(/",/g, ';')
    .replace(/"/g, '')
    .replace(/[{}]/g, '')
    .trim()

  const fileData = `body {
      ${dataStr}
    }
    `
    .replace('     ', ' ')
    .replace('    }', '}')

  const blob = new Blob([fileData], { type: 'text/plain' })
  const fileName = `${name} theme.css`

  return {
    blob,
    fileName,
  }
}

export const exportFile = (fileInfo: CreateFileInfo) => {
  const { blob, fileName } = fileInfo

  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = fileName
  link.href = url
  link.click()
  link.remove()
}

export const exportCss = (appContext: IAppContext, name: string) => {
  exportFile(createCss(appContext, name))
}

export const exportJson = (appContext: IAppContext, name: string) => {
  exportFile(createJson(appContext, name))
}
