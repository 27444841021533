// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WQFES{display:inline-block;cursor:pointer;border-radius:64px}.KTz2M{position:absolute;z-index:2;top:65px}.zDNaF{position:fixed;top:0;right:0;bottom:0;left:0}.CdExi{margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/components/ColorPicker/ColorPicker.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,cAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,SAAA,CACA,QAAA,CAGF,OACE,cAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CAGF,OACE,kBAAA","sourcesContent":[".swatch {\n  display: inline-block;\n  cursor: pointer;\n  border-radius: 64px;\n}\n\n.popover {\n  position: absolute;\n  z-index: 2;\n  top: 65px;\n}\n\n.cover {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n.sketchPicker {\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swatch": `WQFES`,
	"popover": `KTz2M`,
	"cover": `zDNaF`,
	"sketchPicker": `CdExi`
};
export default ___CSS_LOADER_EXPORT___;
