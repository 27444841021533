import React, { useEffect, useState } from 'react'

import { ColorResult, SketchPicker } from 'react-color'

import styles from './ColorPicker.module.scss'

export const SketchExample: React.FC<{
  onChange: (color: string) => void
  value: string
  style?: Record<string, string>
}> = ({ onChange, value, style = {} }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [selectedColor, setSelectedColor] = useState<string>('')

  useEffect(() => {
    setSelectedColor(value)
  }, [value])

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const convertOpacityToHex = (value: number) => {
    const convertedValue = Math.round((value / 1) * 255)
    const hexValue = convertedValue.toString(16).toUpperCase().padStart(2, '0')

    return hexValue
  }

  const handleChange = (color: ColorResult) => {
    let finalColorValue: string = color.hex

    if (color.rgb.a && color.rgb.a < 1) {
      finalColorValue += convertOpacityToHex(color.rgb.a)
    }

    setSelectedColor(finalColorValue)
    onChange(finalColorValue)
  }

  const stylesForColorPicker = {
    colorPicker: {
      width: '20px',
      height: '20px',
      borderRadius: '64px',
      background: selectedColor,
      ...style,
    },
  }

  return (
    <>
      <div className={styles.swatch} onClick={handleClick}>
        <div style={stylesForColorPicker.colorPicker} />
      </div>
      {displayColorPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={handleClose} />
          <SketchPicker className={styles.sketchPicker} color={selectedColor} onChange={handleChange} />
        </div>
      )}
    </>
  )
}
