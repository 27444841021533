// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G98Sn{display:flex;flex-direction:column}.G98Sn .lZv9L{height:80px;margin:8px 0 20px 0}.G98Sn .lZv9L::part(card){display:flex;justify-content:center;width:376px}.G98Sn .lZv9L .DQ0oW{width:120px;height:48px;background-color:var(--wpp-white-color);border:2px solid var(--wpp-grey-color-1000)}.G98Sn .qyBJv{margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/ObjectStyles/components/Radius/components/RadiusUsageDetails/RadiusUsageDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CAEA,cACE,WAAA,CACA,mBAAA,CAEA,0BACE,YAAA,CACA,sBAAA,CACA,WAAA,CAGF,qBACE,WAAA,CACA,WAAA,CACA,uCAAA,CACA,2CAAA,CAIJ,cACE,kBAAA","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n\n  .card {\n    height: 80px;\n    margin: 8px 0 20px 0;\n\n    &::part(card) {\n      display: flex;\n      justify-content: center;\n      width: 376px;\n    }\n\n    .preview {\n      width: 120px;\n      height: 48px;\n      background-color: var(--wpp-white-color);\n      border: 2px solid var(--wpp-grey-color-1000);\n    }\n  }\n\n  .divider {\n    margin-bottom: 20px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `G98Sn`,
	"card": `lZv9L`,
	"preview": `DQ0oW`,
	"divider": `qyBJv`
};
export default ___CSS_LOADER_EXPORT___;
