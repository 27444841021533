// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uMKcv .cTnSh{padding:12px;background-color:#eee8ff;margin:20px 0}.uMKcv .tlxt4{display:flex;align-items:center}.uMKcv .tlxt4:not(:last-child){margin-bottom:8px}.uMKcv .tlxt4 .Umm7U{margin-right:10px}.uMKcv .rcV31{margin-top:20px}.uMKcv .Gt75S{margin-bottom:16px}.uMKcv .v6bUY::part(list-wrapper){display:flex}.uMKcv .xBIAD{display:flex;justify-content:flex-end}.uMKcv .xBIAD :first-child{margin-right:12px}.uMKcv .oKZku{margin-top:10px}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/Typography/components/UploadFontModal/UploadFontModal.module.scss"],"names":[],"mappings":"AACE,cACE,YAAA,CACA,wBAAA,CACA,aAAA,CAGF,cACE,YAAA,CACA,kBAAA,CAEA,+BACE,iBAAA,CAGF,qBACE,iBAAA,CAIJ,cACE,eAAA,CAGF,cACE,kBAAA,CAIA,kCACE,YAAA,CAIJ,cACE,YAAA,CACA,wBAAA,CAEA,2BACE,iBAAA,CAIJ,cACE,eAAA","sourcesContent":[".modal {\n  .hints {\n    padding: 12px;\n    background-color: #EEE8FF;\n    margin: 20px 0;\n  }\n\n  .hint {\n    display: flex;\n    align-items: center;\n\n    &:not(:last-child) {\n      margin-bottom: 8px;\n    }\n\n    .tick {\n      margin-right: 10px;\n    }\n  }\n\n  .familyName {\n    margin-top: 20px;\n  }\n\n  .description {\n    margin-bottom: 16px;\n  }\n\n  .fileUpload {\n    &::part(list-wrapper) {\n      display: flex;\n    }\n  }\n\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n\n    :first-child {\n      margin-right: 12px;\n    }\n  }\n\n  .errorMessage {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `uMKcv`,
	"hints": `cTnSh`,
	"hint": `tlxt4`,
	"tick": `Umm7U`,
	"familyName": `rcV31`,
	"description": `Gt75S`,
	"fileUpload": `v6bUY`,
	"actions": `xBIAD`,
	"errorMessage": `oKZku`
};
export default ___CSS_LOADER_EXPORT___;
