import React from 'react'

import { WppCard, WppTypography, WppTag } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useNavigate } from 'react-router-dom'

import { ExportAsButton } from 'components/ExportAsButton/ExportAsButton'

import styles from './ActiveThemeCard.module.scss'

export const ActiveThemeCard: React.FC = () => {
  const navigate = useNavigate()

  const { osContext } = useOs()

  const currentDate = new Date().toLocaleString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })

  const handleCardClick = () => {
    navigate('/theme/edit')
  }

  return (
    <WppCard interactive className={styles.activeThemeCard} onClick={handleCardClick}>
      <ExportAsButton isMainPage />
      <div className={styles.body}>
        <div className={styles.colorsBlock} />
        <div className={styles.information}>
          <div className={styles.themeName}>
            <WppTypography type="l-strong">{osContext.tenant.name} Theme</WppTypography>
            <WppTag label="Active" variant="positive" className={styles.tag} />
          </div>
          <WppTypography type="xs-body" className={styles.dateInfo}>
            Last opened {currentDate}
          </WppTypography>
        </div>
      </div>
    </WppCard>
  )
}
