import React from 'react'

import { createTheme } from '@platform-ui-kit/components-library'
import {
  WppButton,
  WppActionButton,
  WppInput,
  WppTypography,
  WppFilterButton,
  WppCard,
  WppIconMore,
  WppPillGroup,
  WppPill,
  WppIconPlus,
  WppFloatingButton,
  WppBackToTopButton,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSelect,
  WppListItem,
  WppDatepicker,
  WppIconFavorites,
  WppIconLike,
  WppIconExtension,
  WppIconPremium,
} from '@platform-ui-kit/components-library-react'

import { useAppContext } from 'app/context'
import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import styles from './ObjectStylesPreview.module.scss'

export const ObjectStylesPreview: React.FC = () => {
  const appContext = useAppContext()
  const theme = createTheme(getThemeFromCreateThemeInfo(appContext) as any)

  return (
    <div style={theme}>
      <WppTypography className={styles.titlePreviewSection} type="2xs-strong">
        Object style
      </WppTypography>
      <div className={styles.previewItems}>
        <WppButton>
          Primary
          <WppIconPlus slot="icon-start" />
        </WppButton>
        <WppButton variant="secondary">
          Secondary
          <WppIconPlus slot="icon-start" />
        </WppButton>
        <WppActionButton>
          Primary
          <WppIconPlus slot="icon-start" />
        </WppActionButton>
        <WppFloatingButton disabled />
        <WppBackToTopButton />
      </div>
      <div className={styles.previewItems}>
        <WppInput type="search" placeholder="Search" className={styles.searchInput} />
        <WppSegmentedControl value="1" size="s">
          <WppSegmentedControlItem value="1">Text</WppSegmentedControlItem>
          <WppSegmentedControlItem value="2">Text</WppSegmentedControlItem>
          <WppSegmentedControlItem value="3">Text</WppSegmentedControlItem>
        </WppSegmentedControl>
        <WppFilterButton>Filters</WppFilterButton>
      </div>
      <div className={styles.previewItems}>
        <WppSelect value={1} labelConfig={{ text: 'Label' }} required name="select-preview">
          <WppListItem value={1}>
            <p slot="label">Text 1</p>
          </WppListItem>
          <WppListItem value={2}>
            <p slot="label">Text 2</p>
          </WppListItem>
          <WppListItem value={3}>
            <p slot="label">Text 3</p>
          </WppListItem>
        </WppSelect>
        <WppDatepicker range labelConfig={{ text: 'Label' }} required name="datepicker-preview" />
      </div>
      <div className={`${styles.previewItems} ${styles.pillGroup}`}>
        <WppPillGroup type="multiple" value={['item-a', 'item-b', 'item-c', 'item-d']}>
          <WppPill label="Item A" value="item-a">
            <WppIconFavorites slot="icon-start" />
          </WppPill>
          <WppPill label="Item B" value="item-b">
            <WppIconLike slot="icon-start" />
          </WppPill>
          <WppPill label="Item C" value="item-c">
            <WppIconExtension slot="icon-start" />
          </WppPill>
          <WppPill label="Item C" value="item-d">
            <WppIconPremium slot="icon-start" />
          </WppPill>
        </WppPillGroup>
      </div>
      <div className={styles.previewItems}>
        <WppCard interactive size="m" className={styles.card}>
          <WppTypography type="m-strong">Clickable card</WppTypography>
          <WppActionButton slot="actions">
            <WppIconMore slot="icon-start" direction="horizontal" />
          </WppActionButton>
        </WppCard>
      </div>
      <div className={styles.previewItems}>
        <div className={styles.modal}>
          <WppTypography type="xl-heading" className={styles.title}>
            Modal
          </WppTypography>
          <WppTypography className={styles.body}>Placeholder text</WppTypography>
          <div className={styles.actions}>
            <WppActionButton variant="secondary" className={styles.firstButton}>
              Button
            </WppActionButton>
            <WppButton size="s">Button</WppButton>
          </div>
        </div>
      </div>
    </div>
  )
}
