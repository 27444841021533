import React, { Fragment, useState } from 'react'

import { createTheme } from '@platform-ui-kit/components-library'
import {
  WppButton,
  WppIconCodeView,
  WppIconExportFile,
  WppIconFileCss,
  WppIconFilePdf,
  WppIconFileZip,
  WppListItem,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
  WppIconEdit,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from 'app/context'
import { useToast } from 'hooks/useToast'
import { getCreateThemeInfoFromTheme, getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import { exportJson, exportFile } from './utils'
import { THEME_BUILDER_API_URL } from '../../config'
import { recursiveObjectFilter } from '../../utils'

type FileType = 'pdf' | 'zip' | 'css'

const FILE_TYPE_MIMETYPE_MAPPING: Record<FileType, string> = {
  pdf: 'application/pdf',
  zip: 'application/zip',
  css: 'text/css',
}

export const ExportAsButton: React.FC<{ isMainPage?: boolean }> = ({ isMainPage }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { osContext } = useOs()
  const appContext = useAppContext()
  const { showToast } = useToast()

  const navigate = useNavigate()

  const getAppContext = () =>
    isMainPage ? { ...appContext, createTheme: getCreateThemeInfoFromTheme(osContext.theme) } : appContext

  const handleExport = (fileType: FileType) => {
    try {
      setIsLoading(true)

      fetch(`${THEME_BUILDER_API_URL}/files-export/generate/${fileType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          theme: getThemeFromCreateThemeInfo(getAppContext()),
          flatTheme: createTheme(
            recursiveObjectFilter(getThemeFromCreateThemeInfo(getAppContext()), value => !!value) as any,
          ),
          themeName: getThemeName(),
        }),
      })
        .then(response => {
          if (response.ok) {
            return response.arrayBuffer()
          } else {
            throw new Error('Failed to load file')
          }
        })
        .then(arrayBuffer => {
          setIsLoading(false)
          const uint8Array = new Uint8Array(arrayBuffer)
          const blob = new Blob([uint8Array], { type: FILE_TYPE_MIMETYPE_MAPPING[fileType] })

          exportFile({
            blob,
            fileName: getThemeName(),
          })

          showToast({
            message: 'Theme has been exported successfully!',
            type: 'success',
            duration: 4000,
          })
        })
        .catch(() => {
          setIsLoading(false)
          showToast({
            message: 'Something went wrong, please try again!',
            type: 'error',
            duration: 4000,
          })
        })
    } catch (e) {
      showToast({
        message: 'Something went wrong, please try again!',
        type: 'error',
        duration: 4000,
      })
    }
  }

  const getThemeName = (): string => {
    if (isMainPage) {
      return osContext.tenant.name
    }

    return appContext.createTheme.name || osContext.tenant.name
  }

  const handleJsonExport = () => {
    try {
      exportJson(getAppContext(), getThemeName())

      showToast({
        message: 'Theme has been exported successfully!',
        type: 'success',
        duration: 4000,
      })
    } catch (e) {
      showToast({
        message: 'Something went wrong, please try again!',
        type: 'error',
        duration: 4000,
      })
    }
  }

  return (
    <WppMenuContext slot="actions" listWidth="200px" onClick={e => e.stopPropagation()}>
      {isMainPage ? (
        <WppActionButton variant="secondary" slot="trigger-element">
          <WppIconMore direction="horizontal" slot="icon-start" />
        </WppActionButton>
      ) : (
        <WppButton slot="trigger-element">
          {isLoading ? (
            <Fragment>Loading...</Fragment>
          ) : (
            <Fragment>
              Export as
              <WppIconExportFile slot="icon-start" />
            </Fragment>
          )}
        </WppButton>
      )}
      {isMainPage && (
        <WppListItem onWppChangeListItem={() => navigate('/theme/edit')}>
          <p slot="label">Edit theme</p>
          <WppIconEdit slot="left" />
        </WppListItem>
      )}
      <WppListItem onWppChangeListItem={handleJsonExport}>
        <p slot="label">{isMainPage && 'Export '}JSON</p>
        <WppIconCodeView slot="left" />
      </WppListItem>
      <WppListItem onWppChangeListItem={() => handleExport('css')}>
        <p slot="label">{isMainPage && 'Export '}CSS</p>
        <WppIconFileCss slot="left" />
      </WppListItem>
      <WppListItem onWppChangeListItem={() => handleExport('pdf')}>
        <p slot="label">{isMainPage && 'Export '}PDF</p>
        <WppIconFilePdf slot="left" />
      </WppListItem>
      {!isMainPage && (
        <WppListItem onWppChangeListItem={() => handleExport('zip')}>
          <p slot="label">ZIP</p>
          <WppIconFileZip slot="left" />
        </WppListItem>
      )}
    </WppMenuContext>
  )
}
