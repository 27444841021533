// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o5Kpo{margin:27px 0 24px 0;color:var(--wpp-text-color-info)}.CjVSs{margin-bottom:8px}.y0_eA{display:flex;flex-direction:column}.y0_eA .jmo4O{display:flex;align-items:center;justify-content:space-between;margin-bottom:8px}.y0_eA .jmo4O .NSAW_{display:flex;align-items:center}.y0_eA .jmo4O .NSAW_ .FVZtn{margin-right:12px}.N5XFF{margin:40px 0}.QBmSu{display:flex;align-items:center;justify-content:space-between}.CW61y{display:flex;align-items:center;margin:40px 0}.CW61y .vziXC{width:216px;margin-right:24px}.hp7X4{margin-left:30px;margin-bottom:202px}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/Typography/components/TypographyPreview/TypographyPreview.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,gCAAA,CAGF,OACE,iBAAA,CAGF,OACE,YAAA,CACA,qBAAA,CAEA,cACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA,CAEA,qBACE,YAAA,CACA,kBAAA,CAEA,4BACE,iBAAA,CAMR,OACE,aAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,aAAA,CAGA,cACE,WAAA,CACA,iBAAA,CAIJ,OACE,gBAAA,CACA,mBAAA","sourcesContent":[".titlePreviewSection {\n  margin: 27px 0 24px 0;\n  color: var(--wpp-text-color-info);\n}\n\n.breadcrumb {\n  margin-bottom: 8px;\n}\n\n.titleDescriptionWrapper {\n  display: flex;\n  flex-direction: column;\n\n  .titleAndActions {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 8px;\n\n    .actions {\n      display: flex;\n      align-items: center;\n\n      .quaternaryBtn {\n        margin-right: 12px;\n      }\n    }\n  }\n}\n\n.tabs {\n  margin: 40px 0;\n}\n\n.inputSegmentedWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.dropdowns {\n  display: flex;\n  align-items: center;\n  margin: 40px 0;\n\n\n  .singleSelect {\n    width: 216px;\n    margin-right: 24px;\n  }\n}\n\n.pillGroup {\n  margin-left: 30px;\n  margin-bottom: 202px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titlePreviewSection": `o5Kpo`,
	"breadcrumb": `CjVSs`,
	"titleDescriptionWrapper": `y0_eA`,
	"titleAndActions": `jmo4O`,
	"actions": `NSAW_`,
	"quaternaryBtn": `FVZtn`,
	"tabs": `N5XFF`,
	"inputSegmentedWrapper": `QBmSu`,
	"dropdowns": `CW61y`,
	"singleSelect": `vziXC`,
	"pillGroup": `hp7X4`
};
export default ___CSS_LOADER_EXPORT___;
