// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SHNbW{display:flex;flex-direction:column}.SHNbW .K0FAL{width:360px;height:100px;margin-bottom:20px}.SHNbW .K0FAL .S6Cwo{width:220px;height:50px}.SHNbW .K0FAL .S6Cwo::part(card){box-shadow:var(--custom-box-shadow)}.SHNbW .K0FAL::part(card){display:flex;justify-content:center;align-items:center}.SHNbW .deRp6{margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/ObjectStyles/components/BoxShadow/components/BoxShadowUsageDetails/BoxShadowUsageDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CAEA,cACE,WAAA,CACA,YAAA,CACA,kBAAA,CAEA,qBACE,WAAA,CACA,WAAA,CAEA,iCACE,mCAAA,CAIJ,0BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAIJ,cACE,kBAAA","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n\n  .card {\n    width: 360px;\n    height: 100px;\n    margin-bottom: 20px;\n\n    .preview {\n      width: 220px;\n      height: 50px;\n\n      &::part(card) {\n        box-shadow: var(--custom-box-shadow);\n      }\n    }\n\n    &::part(card) {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n\n  .divider {\n    margin-bottom: 20px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `SHNbW`,
	"card": `K0FAL`,
	"preview": `S6Cwo`,
	"divider": `deRp6`
};
export default ___CSS_LOADER_EXPORT___;
