import React, { FC, PropsWithChildren } from 'react'

import { createTheme } from '@platform-ui-kit/components-library'
import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { WppCard, WppDivider, WppTypography } from '@platform-ui-kit/components-library-react'

import { useAppContext } from 'app/context'
import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import styles from './TypographyUsageDetails.module.scss'

export const TypographyUsageDetails: FC<
  PropsWithChildren<{ title: string; previewText: string; type: string; style?: React.CSSProperties }>
> = ({ title, previewText, type, children, style }) => {
  const appContext = useAppContext()
  const theme = createTheme(getThemeFromCreateThemeInfo(appContext) as any)

  return (
    <div className={styles.body} style={style}>
      <WppTypography type="s-strong" className={styles.header}>
        {title}
      </WppTypography>
      <WppCard className={styles.card} variant="secondary">
        <WppTypography type={type as TypographyType} style={theme}>
          {previewText}
        </WppTypography>
      </WppCard>
      <WppDivider className={styles.divider} />
      {children}
    </div>
  )
}
