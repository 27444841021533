import React, { useEffect } from 'react'

import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'

import { useAppContext } from 'app/context'
import { RADIUS_SIZES, BOX_SHADOW_SIZES } from 'pages/manage-theme/consts'
import { CreateTheme } from 'pages/manage-theme/types'

import { BoxShadow } from './components/BoxShadow/BoxShadow'
import { Radius } from './components/Radius/Radius'
import styles from './ObjectStylesSection.module.scss'

export const ObjectStylesSection: React.FC = () => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()

  const { setCreateTheme, createTheme } = appContext

  useEffect(() => {
    setCreateTheme({ ...formik.values, name: createTheme.name, font: createTheme.font })
  }, [createTheme.name, createTheme.font, formik.values, setCreateTheme])

  return (
    <div className={styles.objectStylesPage}>
      <WppTypography type="s-body" className={styles.description}>
        Detailed guidance about object styles on the{' '}
        <a href="https://developers-ch-hulk.os-dev.io/" className={styles.link}>
          Developer Hub
        </a>{' '}
        provides all the information how to generate pallets, use external plugins and so on.
      </WppTypography>
      <WppTypography type="xl-heading" className={styles.sectionTitle}>
        Border radius
      </WppTypography>
      {RADIUS_SIZES.map(size => (
        <Radius
          type={size.fieldPath}
          key={size.fieldPath}
          usageDetails={size.usageDetails}
          description={size.description}
        />
      ))}

      <WppTypography type="xl-heading" className={styles.sectionTitle}>
        Box shadow
      </WppTypography>
      {BOX_SHADOW_SIZES.map(size => (
        <BoxShadow type={size.fieldPath} key={size.fieldPath} usageDetails={size.usageDetails} />
      ))}
    </div>
  )
}
