// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mjQoT .ArQjG{margin-bottom:16px}.mjQoT .Y_gBr::part(list-wrapper){display:flex}.mjQoT .TYSVt{display:flex;justify-content:flex-end}.mjQoT .TYSVt :first-child{margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/pages/home/components/ImportJsonModal/ImportJsonModal.module.scss"],"names":[],"mappings":"AACE,cACE,kBAAA,CAIA,kCACE,YAAA,CAIJ,cACE,YAAA,CACA,wBAAA,CAEA,2BACE,iBAAA","sourcesContent":[".modal {\n  .description {\n    margin-bottom: 16px;\n  }\n\n  .fileUpload {\n    &::part(list-wrapper) {\n      display: flex;\n    }\n  }\n\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n\n    :first-child {\n      margin-right: 12px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `mjQoT`,
	"description": `ArQjG`,
	"fileUpload": `Y_gBr`,
	"actions": `TYSVt`
};
export default ___CSS_LOADER_EXPORT___;
