// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._hnut{display:inline-flex;flex-direction:column;width:784px}._hnut .S3sxR{margin:24px 0 16px 0}._hnut .IGeQM{width:480px}._hnut .IGeQM .pGpS5{color:var(--wpp-primary-color-500);text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/ObjectStyles/ObjectStylesSection.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CACA,qBAAA,CACA,WAAA,CAEA,cACE,oBAAA,CAGF,cACE,WAAA,CAEA,qBACE,kCAAA,CACA,yBAAA","sourcesContent":[".objectStylesPage {\n  display: inline-flex;\n  flex-direction: column;\n  width: 784px;\n\n  .sectionTitle {\n    margin: 24px 0 16px 0;\n  }\n\n  .description {\n    width: 480px;\n\n    .link {\n      color: var(--wpp-primary-color-500);\n      text-decoration: underline;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"objectStylesPage": `_hnut`,
	"sectionTitle": `S3sxR`,
	"description": `IGeQM`,
	"link": `pGpS5`
};
export default ___CSS_LOADER_EXPORT___;
