export const uuidv4 = (): string =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )

export const loadStyleSheet = (stylePath: string, loadHandler: () => void) => {
  const existingSheets = Array.from(document.getElementsByTagName('link'))

  // skip stylesheet if exists
  const hasLink = existingSheets.some(stylesheet => (stylesheet.href as string) === stylePath)
  if (hasLink) {
    loadHandler()
    return
  }

  const head = document.head
  const link = document.createElement('link')

  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = stylePath

  head.appendChild(link)

  link.addEventListener('load', () => {
    loadHandler()
  })

  return () => {
    head.removeChild(link)
  }
}

export const isObject = <T>(val: T) => typeof val === 'object' && val !== null

export const recursiveObjectMap = <T>(initObj: T, mapFunction: (a: string | number) => string | number): T => {
  const obj = { ...initObj }

  for (const k in obj) {
    if (isObject(obj[k])) {
      obj[k] = recursiveObjectMap<T[Extract<keyof T, string>]>(obj[k], mapFunction)
    } else {
      obj[k] = mapFunction(obj[k] as string | number) as unknown as T[Extract<keyof T, string>]
    }
  }

  return obj
}

export const recursiveObjectFilter = <T>(initObj: T, filterFunction: (a: string | number) => boolean): T => {
  const obj = { ...initObj }

  for (const k in obj) {
    if (isObject(obj[k])) {
      obj[k] = recursiveObjectFilter<T[Extract<keyof T, string>]>(obj[k], filterFunction)
    } else {
      if (!filterFunction(obj[k] as string | number)) {
        delete obj[k]
      }
    }
  }

  return obj
}
