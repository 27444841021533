import React, { Fragment } from 'react'

import {
  WppExpandableCard,
  WppTypography,
  WppTooltip,
  WppIconReset,
  WppActionButton,
  WppIconBookInformation,
  WppTabs,
  WppTab,
  WppCard,
  WppCardGroup,
} from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { capitalize, get, size } from 'lodash'

import { useAppContext } from 'app/context'
import { SketchExample } from 'components/ColorPicker/ColorPicker'
import { FormInput } from 'components/FormInput/FormInput'
import { HEADER_INFO, PALETTE_TITLES } from 'pages/manage-theme/consts'
import { ColorPalette, CreateTheme } from 'pages/manage-theme/types'
import { addSuffixToPaletteColorFieldName, getColorContrast } from 'pages/manage-theme/utils'

import styles from './PaletteSection.module.scss'

export const PaletteSection: React.FC<ColorPalette> = ({ title, description, fields = [], fieldPath, sections }) => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()
  const currSectionPath = `${fieldPath}.currSection`
  const formikCurrentTab = get(formik.values, currSectionPath, '')

  const handleTabChange = (event: CustomEvent) => {
    formik.setFieldValue(currSectionPath, event.detail.value)
  }

  const handleColorChange = (inputField: string, color: string) => {
    formik.setFieldValue(inputField, color)
  }

  const handleCardGroupChange = (event: CustomEvent) => {
    formik.setFieldValue(currSectionPath, event.detail.value)
  }

  const selectedSection = sections.find(section => section.value === formikCurrentTab)
  const sectionFields = selectedSection ? selectedSection.fields : fields

  return (
    <WppExpandableCard className={styles.paletteSectionWrapper} size="l">
      <WppTypography slot="header">
        <WppTypography type="l-strong">{title}</WppTypography>
      </WppTypography>

      <div>
        <WppTypography type="s-body">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </WppTypography>

        {size(sections) > 0 && (
          <Fragment>
            {title === PALETTE_TITLES.DATA_VIZ_CATEGORICAL || title === PALETTE_TITLES.DATA_VIZ_SEQUENTIAL ? (
              <WppTabs value={formikCurrentTab} onWppChange={handleTabChange} className={styles.sectionSelector}>
                {sections.map(section => (
                  <WppTab value={section.value} key={section.name}>
                    {section.name}
                  </WppTab>
                ))}
              </WppTabs>
            ) : (
              <WppCardGroup
                className={styles.cardSelector}
                value={formikCurrentTab}
                withRadioOrCheckbox={false}
                onWppChange={handleCardGroupChange}
              >
                {sections!.map(section => (
                  <WppCard className={styles.item} value={section.value} key={section.name}>
                    <WppTypography
                      slot="header"
                      type={formikCurrentTab === section.value ? 's-strong' : 's-body'}
                      style={
                        formikCurrentTab === section.value
                          ? { color: 'var(--wpp-primary-color-500)', width: '100%' }
                          : { width: '100%' }
                      }
                    >
                      {section.name}
                    </WppTypography>
                  </WppCard>
                ))}
              </WppCardGroup>
            )}
          </Fragment>
        )}

        {title === PALETTE_TITLES.DATA_VIZ_SEQUENTIAL && (
          <WppTypography type="m-strong" className={styles.currentSection}>
            {capitalize(formikCurrentTab)}
          </WppTypography>
        )}

        <div className={styles.paletteTable}>
          <table>
            <tbody>
              <tr className={styles.tableHeaderRow}>
                {Object.entries(HEADER_INFO).map(([header, width]) => (
                  <th key={header} style={{ width }}>
                    <div className={styles.tableCell}>
                      <WppTypography type="s-strong">{header}</WppTypography>
                    </div>
                  </th>
                ))}
              </tr>
              {sectionFields.map(row => {
                const inputName = addSuffixToPaletteColorFieldName(
                  [fieldPath, selectedSection?.fieldPath, row.fieldPath].filter(Boolean).join('.'),
                )
                const inputValue = get(formik.values, inputName, '') as string
                const initialValue = get(formik.initialValues, inputName, '') as string
                const isChanged = inputValue !== initialValue

                return (
                  <tr className={styles.tableRow} key={row.name}>
                    <td>
                      <div className={styles.tableCell}>
                        <SketchExample
                          value={inputValue}
                          onChange={data => handleColorChange(inputName, data)}
                          style={{
                            width: '72px',
                            height: '48px',
                            borderRadius: '4px',
                            border: inputValue && inputValue !== '#ffffff' ? '' : 'solid 1px var(--wpp-grey-color-600)',
                            position: 'absolute',
                            top: '10px',
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className={styles.tableCell}>
                        <FormInput label="" name={inputName} placeholder="Enter Hex" type="text" />
                      </div>
                    </td>
                    <td>
                      <div className={styles.tableCell}>
                        <WppTypography type="s-body">{row.name}</WppTypography>
                        {row.description && (
                          <Fragment>
                            <WppTypography type="s-body">{row.description}</WppTypography>
                          </Fragment>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className={styles.tableCell}>
                        <WppTypography type="s-body">{getColorContrast(inputValue)}</WppTypography>
                      </div>
                    </td>

                    <td style={{ textAlign: 'right' }}>
                      {isChanged && (
                        <WppTooltip text="Reset all changes" onClick={() => handleColorChange(inputName, initialValue)}>
                          <WppActionButton variant="secondary">
                            <WppIconReset slot="icon-start" />
                          </WppActionButton>
                        </WppTooltip>
                      )}
                      <WppTooltip text="See usage details" onClick={() => appContext.setUsageDetails(row.usageDetails)}>
                        <WppActionButton variant="secondary">
                          <WppIconBookInformation slot="icon-start" />
                        </WppActionButton>
                      </WppTooltip>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </WppExpandableCard>
  )
}
