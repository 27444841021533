// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HtgmF{display:inline-flex;flex-direction:column;width:784px}.HtgmF .QXDtC{width:480px;margin-bottom:24px}.HtgmF .QXDtC .mvPKn{color:var(--wpp-primary-color-500);text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/Colors/ColorsSection.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CACA,qBAAA,CACA,WAAA,CAEA,cACE,WAAA,CACA,kBAAA,CAEA,qBACE,kCAAA,CACA,yBAAA","sourcesContent":[".colorsPage {\n  display: inline-flex;\n  flex-direction: column;\n  width: 784px;\n\n  .description {\n    width: 480px;\n    margin-bottom: 24px;\n\n    .link {\n      color: var(--wpp-primary-color-500);\n      text-decoration: underline;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorsPage": `HtgmF`,
	"description": `QXDtC`,
	"link": `mvPKn`
};
export default ___CSS_LOADER_EXPORT___;
