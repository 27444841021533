import { WppTypography } from '@platform-ui-kit/components-library-react'

import { ColorsUsageDetails } from 'pages/manage-theme/components/Colors/components/ColorsUsageDetails/ColorsUsageDetails'
import { BoxShadowUsageDetails } from 'pages/manage-theme/components/ObjectStyles/components/BoxShadow/components/BoxShadowUsageDetails/BoxShadowUsageDetails'
import { RadiusUsageDetails } from 'pages/manage-theme/components/ObjectStyles/components/Radius/components/RadiusUsageDetails/RadiusUsageDetails'
import { TypographyUsageDetails } from 'pages/manage-theme/components/Typography/components/TypographyUsageDetails/TypographyUsageDetails'
import {
  ColorPalette,
  PaletteField,
  TypographyTypeSection,
  UsageDetails,
  RadiusSizeSection,
  BoxShadowSection,
} from 'pages/manage-theme/types'

export const RADIUS_SIZES: RadiusSizeSection[] = [
  {
    title: 'xs',
    description: '',
    fieldPath: 'xs',
    usageDetails: {
      content: (
        <RadiusUsageDetails title="Border radius XS" type="xs">
          <div>
            <WppTypography type="s-strong">Checkbox</WppTypography> <br />
            <WppTypography type="s-strong">Tag</WppTypography> <br />
            <WppTypography type="s-strong">File Upload:</WppTypography>
            <WppTypography type="s-body">Uploaded Item</WppTypography>
          </div>
        </RadiusUsageDetails>
      ),
    },
  },
  {
    title: 's',
    description: '',
    fieldPath: 's',
    usageDetails: {
      content: (
        <RadiusUsageDetails title="Border radius S" type="s">
          <div>
            <WppTypography type="s-strong">S-size:&nbsp;</WppTypography>
            <WppTypography type="s-body">Buttons, Input, Search, Date Input, Select</WppTypography> <br />
            <WppTypography type="s-strong">Filter Button</WppTypography>
            <WppTypography type="s-strong">Action Button</WppTypography>
            <WppTypography type="s-strong">Drop-down List Menu</WppTypography>
            <WppTypography type="s-strong">Context Menu</WppTypography>
            <WppTypography type="s-strong">Tree </WppTypography>
            <WppTypography type="s-strong">List</WppTypography>
            <WppTypography type="s-strong">Inline Message:&nbsp;</WppTypography>
            <WppTypography type="s-body">M size</WppTypography> <br />
            <WppTypography type="s-strong">Tooltip</WppTypography>
            <WppTypography type="s-strong"> Menu Item</WppTypography>
            <WppTypography type="s-strong">Tooltip</WppTypography>
            <WppTypography type="s-strong">Segment Control:&nbsp;</WppTypography>
            <WppTypography type="s-body"> Selected Item</WppTypography> <br />
          </div>
        </RadiusUsageDetails>
      ),
    },
  },
  {
    title: 'm',
    description: '',
    fieldPath: 'm',
    usageDetails: {
      content: (
        <RadiusUsageDetails title="Border radius M" type="m">
          <div>
            <WppTypography type="s-strong">M-size:&nbsp;</WppTypography>
            <WppTypography type="s-body">Buttons, Input, Search, Date Input, Select</WppTypography> <br />
            <WppTypography type="s-strong">Combined Input</WppTypography> <br />
            <WppTypography type="s-strong">Toast message</WppTypography> <br />
            <WppTypography type="s-strong">Text Area</WppTypography> <br />
            <WppTypography type="s-strong">Counter</WppTypography> <br />
            <WppTypography type="s-strong">Autocomplete</WppTypography> <br />
            <WppTypography type="s-strong">Segment Control</WppTypography> <br />
            <WppTypography type="s-strong">File Upload:&nbsp;</WppTypography>
            <WppTypography type="s-body">Area</WppTypography> <br />
            <WppTypography type="s-strong">Cards</WppTypography>
          </div>
        </RadiusUsageDetails>
      ),
    },
  },
  {
    title: 'l',
    description: '',
    fieldPath: 'l',
    usageDetails: {
      content: (
        <RadiusUsageDetails title="Border radius L" type="l">
          <div>
            <WppTypography type="s-strong">Modal Windows</WppTypography>
          </div>
        </RadiusUsageDetails>
      ),
    },
  },
  {
    title: 'xl',
    description: '',
    fieldPath: 'xl',
    usageDetails: {
      content: (
        <RadiusUsageDetails title="Border radius XL" type="xl">
          <div>
            <WppTypography type="s-strong">-</WppTypography>
          </div>
        </RadiusUsageDetails>
      ),
    },
  },
  {
    title: 'round',
    description: 'Round the edges of the primary, secondary, and action buttons.',
    fieldPath: 'fullyRounded',
    usageDetails: {
      content: <></>,
    },
  },
]

export const BOX_SHADOW_SIZES: BoxShadowSection[] = [
  {
    title: 'xs',
    description: '',
    fieldPath: 'xs',
    usageDetails: {
      content: (
        <BoxShadowUsageDetails title="Box shadow XS" type="xs">
          <WppTypography type="s-strong">Segmented Control: Selected Item</WppTypography>
        </BoxShadowUsageDetails>
      ),
    },
  },
  {
    title: 's',
    description: '',
    fieldPath: 's',
    usageDetails: {
      content: (
        <BoxShadowUsageDetails title="Box shadow S" type="s">
          <div>
            <WppTypography type="s-strong">Card:&nbsp;</WppTypography>
            <WppTypography type="s-body">Idle</WppTypography>
          </div>
        </BoxShadowUsageDetails>
      ),
    },
  },
  {
    title: 'm',
    description: '',
    fieldPath: 'm',
    usageDetails: {
      content: (
        <BoxShadowUsageDetails title="Box shadow M" type="m">
          <div>
            <WppTypography type="s-strong">Drop-downs</WppTypography> <br />
            <WppTypography type="s-strong">Context Menu</WppTypography> <br />
            <WppTypography type="s-strong">Card Clickable:&nbsp;</WppTypography>
            <WppTypography type="s-body">Hover</WppTypography> <br />
            <WppTypography type="s-strong">Tooltip</WppTypography> <br />
          </div>
        </BoxShadowUsageDetails>
      ),
    },
  },
  {
    title: 'brand',
    description: '',
    fieldPath: 'brand',
    usageDetails: {
      content: (
        <BoxShadowUsageDetails title="Box shadow Brand" type="brand">
          <div>
            <WppTypography type="s-strong">Floating Button</WppTypography> <br />
            <WppTypography type="s-strong">Back to Top Button</WppTypography> <br />
          </div>
        </BoxShadowUsageDetails>
      ),
    },
  },
  {
    title: 'l',
    description: '',
    fieldPath: 'l',
    usageDetails: {
      content: (
        <BoxShadowUsageDetails title="Box shadow L" type="l">
          <div>
            <WppTypography type="s-strong">Modal Window</WppTypography> <br />
            <WppTypography type="s-strong">Side Modal</WppTypography> <br />
            <WppTypography type="s-strong">Card Clickable:&nbsp;</WppTypography>
            <WppTypography type="s-body">Pressed</WppTypography>
          </div>
        </BoxShadowUsageDetails>
      ),
    },
  },
]

export enum THEME_OPTIONS {
  edit = 'edit',
  create = 'create',
}

export const PALETTE_TITLES = {
  DATA_VIZ_CATEGORICAL: 'DataViz Categorical Palette',
  DATA_VIZ_SEQUENTIAL: 'DataViz Sequential Palette',
}

export const HEADER_INFO = {
  Preview: 105,
  'Value HEX': 160,
  Name: 212,
  'Contrast :1': 160,
  '': 100,
}

export enum MANAGE_THEME_SECTIONS {
  COLORS = 'colors',
  TYPOGRAPHY = 'typography',
  OBJECT_STYLES = 'object-styles',
}

const createPaletteField = (
  name: string,
  fieldPath: string | number,
  description = '',
  usageDetails: UsageDetails,
): PaletteField => ({
  name,
  fieldPath,
  description,
  usageDetails,
})

export const FULLY_ROUNDED_RADIUS = '100px'

export const COLOR_PALETTES: ColorPalette[] = [
  {
    title: 'Primary Palette',
    description:
      'Primary palette is generated basing on the Brand primary colour. This colour must be the Primary-500 shade. Other shades can be generated by using <a href="https://tailwind.ink" style="text-decoration: underline;">Tailwind</a> plugin.',
    fieldPath: 'color.primary',
    fields: [
      createPaletteField('Primary-100', 100, '', {
        content: (
          <ColorsUsageDetails path="color.primary.100_color" title="Primary-100">
            <div>
              <WppTypography type="s-strong">Used mainly for Hover state</WppTypography> <br /> <br />
              <WppTypography type="s-strong">Regular Primary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Link:&nbsp;</WppTypography>
              <WppTypography type="s-body">Primary, Contextual Link – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Checkbox:&nbsp;</WppTypography>
              <WppTypography type="s-body">Selected - Fill - Hover</WppTypography> <br />
              <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
              <WppTypography type="s-body">Show More – Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">List Item:&nbsp;</WppTypography>
              <WppTypography type="s-body">Checkbox and Title – Selected Hover</WppTypography> <br />
              <WppTypography type="s-strong">Data Zoom:&nbsp;</WppTypography>
              <WppTypography type="s-body">Drag Control, Border – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Tabs:&nbsp;</WppTypography>
              <WppTypography type="s-body">Tab Item – Label – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Checkbox:&nbsp;</WppTypography>
              <WppTypography type="s-body">Checkbox: Fill – Selected Hover, Mixed Hover</WppTypography> <br /> <br />
              <WppTypography type="s-strong">Toggle, Slider, Selectable Card, Date Picker Parts…</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Primary-200', 200, '', {
        content: (
          <ColorsUsageDetails path="color.primary.200_color" title="Primary-200">
            <div>
              <WppTypography type="s-strong">Regular Secondary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Pressed</WppTypography> <br />
              <WppTypography type="s-strong">More Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Pressed</WppTypography> <br />
              <WppTypography type="s-strong">Back To Top Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Pressed</WppTypography> <br />
              <WppTypography type="s-strong">Data Zoom:&nbsp;</WppTypography>
              <WppTypography type="s-body">Overlay – Fill – Idle, hover</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Primary-300', 300, '', {
        content: (
          <ColorsUsageDetails path="color.primary.300_color" title="Primary-300">
            <div>
              <WppTypography type="s-strong">Used mainly for Disabled state.</WppTypography> <br /> <br />
              <WppTypography type="s-strong">Regular Primary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
              <WppTypography type="s-strong">Regular Secondary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Border, Text, Icon – Disabled</WppTypography> <br />
              <WppTypography type="s-strong">Action Primary Button&nbsp;</WppTypography>
              <WppTypography type="s-body">Text, Icon – Disabled</WppTypography> <br />
              <WppTypography type="s-strong">More Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Border, Icon – Disabled</WppTypography> <br />
              <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
              <WppTypography type="s-strong">Data Zoom:&nbsp;</WppTypography>
              <WppTypography type="s-body">Drag Control – Fill – Idle</WppTypography> <br />
              <WppTypography type="s-strong">Checkbox:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Selected, Mixed Disabled</WppTypography> <br /> <br />
              <WppTypography type="s-strong"> Toggle, Radio Button, Slider Parts, Selectable Card…</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Primary-400', 400, '', {
        content: (
          <ColorsUsageDetails path="color.primary.400_color" title="Primary-400">
            <div>
              <WppTypography type="s-strong">Used mainly for Hover state.</WppTypography> <br /> <br />
              <WppTypography type="s-strong">Regular Primary Button::&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Link:&nbsp;</WppTypography>
              <WppTypography type="s-body">Primary, Contextual Link – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Checkbox:&nbsp;</WppTypography>
              <WppTypography type="s-body"> Selected - Fill - Hover</WppTypography> <br />
              <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
              <WppTypography type="s-body">Show More – Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">List Item:&nbsp;</WppTypography>
              <WppTypography type="s-body">Checkbox and Title – Selected Hover</WppTypography> <br />
              <WppTypography type="s-strong">Data Zoom:&nbsp;</WppTypography>
              <WppTypography type="s-body">Drag Control, Border – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Tabs:&nbsp;</WppTypography>
              <WppTypography type="s-body">Tab Item – Label – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Checkbox:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Selected Hover, Mixed Hover</WppTypography> <br /> <br />
              <WppTypography type="s-strong"> Toggle, Slider, Selectable Card, Date Picker Parts…</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Primary-500', 500, '', {
        content: (
          <ColorsUsageDetails path="color.primary.500_color" title="Primary-500">
            <div>
              <WppTypography type="s-strong">Used mainly for Idle, Selected states.</WppTypography> <br /> <br />
              <WppTypography type="s-strong">Regular Primary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Idle, Loading</WppTypography> <br />
              <WppTypography type="s-strong">Regular Secondary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Border, Text, Icon – Idle, Hover</WppTypography> <br />
              <WppTypography type="s-strong">Action Primary Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Text, Icon – Idle, Hover</WppTypography> <br />
              <WppTypography type="s-strong">More Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Icon, Border – Idle, Hover</WppTypography> <br />
              <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Idle, Loading</WppTypography> <br />
              <WppTypography type="s-strong">Back To Top:&nbsp;</WppTypography>
              <WppTypography type="s-body">Border, Icon – Idle, Hover</WppTypography> <br />
              <WppTypography type="s-strong">Primary Link:&nbsp;</WppTypography>
              <WppTypography type="s-body">Idle</WppTypography> <br />
              <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
              <WppTypography type="s-body">Show More – Fill – Idle</WppTypography> <br />
              <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
              <WppTypography type="s-body">Title – Active Selected, Active Selected Hover</WppTypography> <br />
              <WppTypography type="s-strong">List Item:&nbsp;</WppTypography>
              <WppTypography type="s-body">Title, Icon – Selected</WppTypography> <br />
              <WppTypography type="s-strong">Spinner:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill</WppTypography> <br />
              <WppTypography type="s-strong">Progress Indicator:&nbsp;</WppTypography>
              <WppTypography type="s-body">Bar, Circle – Progress Fill</WppTypography> <br />
              <WppTypography type="s-strong">Tabs:&nbsp;</WppTypography>
              <WppTypography type="s-body">Tab Item – Label, Highlight – Selected</WppTypography> <br />
              <WppTypography type="s-strong">Horizontal Stepper:&nbsp;</WppTypography>
              <WppTypography type="s-body">
                Step Indicator – Current – Border, Number; Completed – Fill; Bar – Segment – Fill
              </WppTypography>
              <br />
              <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
              <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                <li>
                  <WppTypography type="s-body">
                    Step Control – Indicator – Selected, Idle, Hover, Pressed; Title – Selected; Divider – Fill
                  </WppTypography>
                </li>
                <li>
                  <WppTypography type="s-body">
                    Sub-step Control – Sub-step Indicator – Current, Completed – Selected Idle, Idle, Hover, Pressed;
                    Title – Selected Idle; Divider – Fill
                  </WppTypography>
                </li>
              </ul>
              <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
              <WppTypography type="s-body">Page Item – Number – Selected Idle</WppTypography> <br />
              <WppTypography type="s-strong">Pills:&nbsp;</WppTypography>
              <WppTypography type="s-body"> Multiple/Single Selection – Border – Selected</WppTypography> <br /> <br />
              <WppTypography type="s-strong">
                Date Picker parts, Radio Button, Checkbox, Toggle, Slider Parts, Selectable Card…
              </WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Primary-600', 600, '', {
        content: (
          <ColorsUsageDetails path="color.primary.600_color" title="Primary-600">
            <div>
              <WppTypography type="s-strong">Used mainly for Pressed state.</WppTypography> <br /> <br />
              <WppTypography type="s-strong">
                Regular Primary Button, Regular Secondary Button, Action Primary Button, More Button, FAB, Back To Top
              </WppTypography>
              <br /> <br />
              <WppTypography type="s-strong">Link:&nbsp;</WppTypography>
              <WppTypography type="s-body">Primary, Contextual Links – Pressed</WppTypography> <br />
              <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
              <WppTypography type="s-body">Show More – Fill – Pressed</WppTypography> <br />
              <WppTypography type="s-strong">List Item:&nbsp;</WppTypography>
              <WppTypography type="s-body">Checkbox and Title – Selected Pressed</WppTypography> <br />
              <WppTypography type="s-strong">Date Picker:&nbsp;</WppTypography>
              <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                <li>
                  <WppTypography type="s-body">Date – Today Unselected – Number – Pressed</WppTypography>
                </li>
                <li>
                  <WppTypography type="s-body">Date – Selected (Today) – Fill – Pressed;</WppTypography>
                </li>
                <li>
                  <WppTypography type="s-body">Range In Between Today – Number – Hover;</WppTypography>
                </li>
                <li>
                  <WppTypography type="s-body">Month/Year – Fill – Selected Pressed</WppTypography>
                </li>
              </ul>
              <WppTypography type="s-strong">Checkbox:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Selected Pressed, Mixed Pressed</WppTypography> <br />
              <WppTypography type="s-strong">Toggle:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Selected Pressed</WppTypography> <br />
              <WppTypography type="s-strong">Pills:&nbsp;</WppTypography>
              <WppTypography type="s-body">
                Multiple/Single Selection – Text, Icon – Selected, Selected Hover; Border – Selected Hover
              </WppTypography>{' '}
              <br />
              <WppTypography type="s-strong">Selectable Card:&nbsp;</WppTypography>
              <WppTypography type="s-body">Border – Selected Pressed</WppTypography> <br />
            </div>
          </ColorsUsageDetails>
        ),
      }),

      createPaletteField('Primary-700', 700, '', {
        content: (
          <ColorsUsageDetails path="color.primary.700_color" title="Primary-700">
            <WppTypography type="s-strong">-</WppTypography>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Primary-800', 800, '', {
        content: (
          <ColorsUsageDetails path="color.primary.800_color" title="Primary-800">
            <WppTypography type="s-strong">-</WppTypography>
          </ColorsUsageDetails>
        ),
      }),
    ],
    sections: [],
  },
  {
    title: 'Greyscale Palette',
    description:
      'There are three pre-field grey palettes with different temperature values. Choose the one that most closely matches the Primary palette.',
    fieldPath: 'color.grey',
    sections: [
      {
        name: 'Cold',
        value: 'cold',
        fields: [
          createPaletteField('Grey-000', '000', '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.000_color" title="Grey-000">
                <div>
                  <WppTypography type="s-strong">Used mainly for Text, Icons on background.</WppTypography> <br />{' '}
                  <br />
                  <WppTypography type="s-strong">Regular Primary and Destructive Buttons:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text, Icon – All States</WppTypography> <br />
                  <WppTypography type="s-strong">Action Inverted Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text, Icon – Idle, Hover</WppTypography> <br />
                  <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – All Sates</WppTypography> <br />
                  <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text Content</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Idle, Disabled; Fill – Multiple Selection – Active Selected
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Data Viz:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Drag Indicator – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Toast:&nbsp;</WppTypography>
                  <WppTypography type="s-body"> No Title – Toast Message; With Title – Title</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Dark – Tooltip Text, Title, Value</WppTypography> <br />
                  <WppTypography type="s-strong">Surfaces (Cards, Modals), Tooltip, Menus… - &nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill Backgrounds</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Info Banner, Step Indicator, Slider, Segmented Control, Toggle, Checkbox, Radio Button…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.100_color" title="Grey-100">
                <div>
                  <WppTypography type="s-strong">Main Background</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover (12%), Pressed (18%), Loading (18%)</WppTypography> <br />
                  <WppTypography type="s-strong">Table Cell:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Selected</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Title+Text – Description Text</WppTypography> <br />
                  <WppTypography type="s-strong">Text Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Date Picker Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Search Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Text Area:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Counter:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Combined Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Date Picker:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Date – Range In Between – Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Single Select:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Autocomplete:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Segmented Control:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Parts – Item Text, Icon – Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Card:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Regular – On Card – Fill</WppTypography> <br />
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.200_color" title="Grey-200">
                <div>
                  <WppTypography type="s-strong">Used mainly for Hover states.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Table Header:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Table Cell:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Context Menu Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover, Expanded</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Hover; Multiple Selection – Fill – Active/Selected Hover
                  </WppTypography>{' '}
                  <br />
                  <WppTypography type="s-strong">Inline Message:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Warning, Informational – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">App Top Bar:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Menu Item – Unselected Hover/Active</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Menu Sub-item – Unselected Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">App Sidebar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Menu Item, Sub-item – Unselected Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Step Control – Fill – Unselected Hover;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Sub-step Control – Fill – Unselected Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Page Item – Fill – Unselected Hover;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Text Select – Fill – Hover, Active, Active Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Filter Button, List Item, Date Picker, Radio Button, Checkbox, Pills, File Upload…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.300_color" title="Grey-300">
                <div>
                  <WppTypography type="s-strong">Used mainly for Pressed states.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Divider:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Data Table:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Cell – Divider – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Context Menu Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body"> Fill – Hover, Expanded</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Pressed; Multiple Selection – Fill – Selected Pressed
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Progress Indicator:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Base Empty – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">App Top Bar:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Menu Item – Fill – Unselected Pressed, Selected;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Menu Sub-item – Fill – Unselected Pressed, Selected</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">App Sidebar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Menu Item, Sub-item – Unselected Pressed, Selected Idle</WppTypography>
                  <br />
                  <WppTypography type="s-strong">Horizontal Stepper:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Step Control – More Indicator – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Step Control – Fill – Unselected Pressed;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Sub-step Control – Fill – Unselected Pressed</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Page Item – Fill – Unselected Pressed; Text Select – Fill – Pressed
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Counter – Left/Right Control – Fill – Pressed/Active</WppTypography>{' '}
                  <br />
                  <WppTypography type="s-strong">Segmented Control:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Background – Fill; Parts – Fill – Idle, Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Slider:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Track, Tick Mark – Fill – Idle</WppTypography> <br />
                  <WppTypography type="s-strong">Selectable Cards:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Disabled Not Selected</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Skeleton, List Item, Date Picker Parts, Radio Button, Checkbox, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.400_color" title="Grey-400">
                <div>
                  <WppTypography type="s-strong">Used mainly for Icons, Borders in Disabled state.</WppTypography>{' '}
                  <br />
                  <br />
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon, Border – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Data Table:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Column Header – Divider – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Stepper:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Step Indicator – Next – Border; Divider – Fill; Sub-step Indicator – Border
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Inputs:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills, Scroll…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.500_color" title="Grey-500">
                <WppTypography type="s-strong">Used mainly for Label/Text in Disabled state.</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.600_color" title="Grey-600">
                <div>
                  <WppTypography type="s-strong">Used mainly for Borders, Icons in Idle state.</WppTypography> <br />{' '}
                  <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Idle</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Filled, Hint</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.700_color" title="Grey-700">
                <div>
                  <WppTypography type="s-strong">Used mainly for Borders in Hover state.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Hint Text</WppTypography>
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill (12%) – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Hover</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.800_color" title="Grey-800">
                <div>
                  <WppTypography type="s-strong">
                    Used mainly for Borders in Pressed state; Action Icons in Hover state.
                  </WppTypography>
                  <br /> <br />
                  <WppTypography type="s-strong">Muted, Supplementary Text</WppTypography>
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – Idle, Hover; Fill (18%) – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border, Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Quiet Link:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Info – Label</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Pressed, Active; Helper Text</WppTypography> <br />
                  <WppTypography type="s-strong">Banner:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Info – Fill</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.900_color" title="Grey-900">
                <div>
                  <WppTypography type="s-strong">Used mainly for Action Icons in Pressed/Active states.</WppTypography>{' '}
                  <br /> <br />
                  <WppTypography type="s-strong">Secondary Action Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text – Idle, Hover; Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text – Idle, Hover; Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Toast:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.grey.cold.1000_color" title="Grey-1000">
                <WppTypography type="s-strong">Paragraph Text, Headings, Labels</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'cold',
      },
      {
        name: 'Neutral',
        value: 'neutral',
        fields: [
          createPaletteField('Grey-000', '000', '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.000_color" title="Grey-000">
                <div>
                  <WppTypography type="s-strong">Used mainly for Text, Icons on background.</WppTypography> <br />{' '}
                  <br />
                  <WppTypography type="s-strong">Regular Primary and Destructive Buttons:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text, Icon – All States</WppTypography> <br />
                  <WppTypography type="s-strong">Action Inverted Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text, Icon – Idle, Hover</WppTypography> <br />
                  <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – All Sates</WppTypography> <br />
                  <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text Content</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Idle, Disabled; Fill – Multiple Selection – Active Selected
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Data Viz:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Drag Indicator – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Toast:&nbsp;</WppTypography>
                  <WppTypography type="s-body"> No Title – Toast Message; With Title – Title</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Dark – Tooltip Text, Title, Value</WppTypography> <br />
                  <WppTypography type="s-strong">Surfaces (Cards, Modals), Tooltip, Menus… - &nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill Backgrounds</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Info Banner, Step Indicator, Slider, Segmented Control, Toggle, Checkbox, Radio Button…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.100_color" title="Grey-100">
                <div>
                  <WppTypography type="s-strong">Main Background</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover (12%), Pressed (18%), Loading (18%)</WppTypography> <br />
                  <WppTypography type="s-strong">Table Cell:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Selected</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Title+Text – Description Text</WppTypography> <br />
                  <WppTypography type="s-strong">Text Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Date Picker Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Search Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Text Area:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Counter:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Combined Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Date Picker:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Date – Range In Between – Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Single Select:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Autocomplete:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Segmented Control:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Parts – Item Text, Icon – Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Card:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Regular – On Card – Fill</WppTypography> <br />
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.200_color" title="Grey-200">
                <div>
                  <WppTypography type="s-strong">Used mainly for Hover states.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Table Header:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Table Cell:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Context Menu Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover, Expanded</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Hover; Multiple Selection – Fill – Active/Selected Hover
                  </WppTypography>{' '}
                  <br />
                  <WppTypography type="s-strong">Inline Message:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Warning, Informational – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">App Top Bar:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Menu Item – Unselected Hover/Active</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Menu Sub-item – Unselected Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">App Sidebar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Menu Item, Sub-item – Unselected Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Step Control – Fill – Unselected Hover;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Sub-step Control – Fill – Unselected Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Page Item – Fill – Unselected Hover;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Text Select – Fill – Hover, Active, Active Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Filter Button, List Item, Date Picker, Radio Button, Checkbox, Pills, File Upload…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.300_color" title="Grey-300">
                <div>
                  <WppTypography type="s-strong">Used mainly for Pressed states.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Divider:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Data Table:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Cell – Divider – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Context Menu Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body"> Fill – Hover, Expanded</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Pressed; Multiple Selection – Fill – Selected Pressed
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Progress Indicator:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Base Empty – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">App Top Bar:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Menu Item – Fill – Unselected Pressed, Selected;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Menu Sub-item – Fill – Unselected Pressed, Selected</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">App Sidebar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Menu Item, Sub-item – Unselected Pressed, Selected Idle</WppTypography>
                  <br />
                  <WppTypography type="s-strong">Horizontal Stepper:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Step Control – More Indicator – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Step Control – Fill – Unselected Pressed;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Sub-step Control – Fill – Unselected Pressed</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Page Item – Fill – Unselected Pressed; Text Select – Fill – Pressed
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Counter – Left/Right Control – Fill – Pressed/Active</WppTypography>{' '}
                  <br />
                  <WppTypography type="s-strong">Segmented Control:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Background – Fill; Parts – Fill – Idle, Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Slider:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Track, Tick Mark – Fill – Idle</WppTypography> <br />
                  <WppTypography type="s-strong">Selectable Cards:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Disabled Not Selected</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Skeleton, List Item, Date Picker Parts, Radio Button, Checkbox, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.400_color" title="Grey-400">
                <div>
                  <WppTypography type="s-strong">Used mainly for Icons, Borders in Disabled state.</WppTypography>{' '}
                  <br />
                  <br />
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon, Border – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Data Table:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Column Header – Divider – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Stepper:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Step Indicator – Next – Border; Divider – Fill; Sub-step Indicator – Border
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Inputs:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills, Scroll…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.500_color" title="Grey-500">
                <WppTypography type="s-strong">Used mainly for Label/Text in Disabled state.</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.600_color" title="Grey-600">
                <div>
                  <WppTypography type="s-strong">Used mainly for Borders, Icons in Idle state.</WppTypography> <br />{' '}
                  <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Idle</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Filled, Hint</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.700_color" title="Grey-700">
                <div>
                  <WppTypography type="s-strong">Used mainly for Borders in Hover state.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Hint Text</WppTypography>
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill (12%) – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Hover</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.800_color" title="Grey-800">
                <div>
                  <WppTypography type="s-strong">
                    Used mainly for Borders in Pressed state; Action Icons in Hover state.
                  </WppTypography>
                  <br /> <br />
                  <WppTypography type="s-strong">Muted, Supplementary Text</WppTypography>
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – Idle, Hover; Fill (18%) – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border, Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Quiet Link:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Info – Label</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Pressed, Active; Helper Text</WppTypography> <br />
                  <WppTypography type="s-strong">Banner:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Info – Fill</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.900_color" title="Grey-900">
                <div>
                  <WppTypography type="s-strong">Used mainly for Action Icons in Pressed/Active states.</WppTypography>{' '}
                  <br /> <br />
                  <WppTypography type="s-strong">Secondary Action Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text – Idle, Hover; Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text – Idle, Hover; Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Toast:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.grey.neutral.1000_color" title="Grey-1000">
                <WppTypography type="s-strong">Paragraph Text, Headings, Labels</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'neutral',
      },
      {
        name: 'Warm',
        value: 'warm',
        fields: [
          createPaletteField('Grey-000', '000', '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.000_color" title="Grey-000">
                <div>
                  <WppTypography type="s-strong">Used mainly for Text, Icons on background.</WppTypography> <br />{' '}
                  <br />
                  <WppTypography type="s-strong">Regular Primary and Destructive Buttons:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text, Icon – All States</WppTypography> <br />
                  <WppTypography type="s-strong">Action Inverted Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text, Icon – Idle, Hover</WppTypography> <br />
                  <WppTypography type="s-strong">FAB:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – All Sates</WppTypography> <br />
                  <WppTypography type="s-strong">Avatar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text Content</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Idle, Disabled; Fill – Multiple Selection – Active Selected
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Data Viz:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Drag Indicator – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Toast:&nbsp;</WppTypography>
                  <WppTypography type="s-body"> No Title – Toast Message; With Title – Title</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Dark – Tooltip Text, Title, Value</WppTypography> <br />
                  <WppTypography type="s-strong">Surfaces (Cards, Modals), Tooltip, Menus… - &nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill Backgrounds</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Info Banner, Step Indicator, Slider, Segmented Control, Toggle, Checkbox, Radio Button…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.100_color" title="Grey-100">
                <div>
                  <WppTypography type="s-strong">Main Background</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover (12%), Pressed (18%), Loading (18%)</WppTypography> <br />
                  <WppTypography type="s-strong">Table Cell:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Selected</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Title+Text – Description Text</WppTypography> <br />
                  <WppTypography type="s-strong">Text Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Date Picker Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Search Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Text Area:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Counter:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Combined Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Date Picker:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Date – Range In Between – Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Single Select:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Autocomplete:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Segmented Control:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Parts – Item Text, Icon – Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Card:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Regular – On Card – Fill</WppTypography> <br />
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.200_color" title="Grey-200">
                <div>
                  <WppTypography type="s-strong">Used mainly for Hover states.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Table Header:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Table Cell:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Context Menu Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover, Expanded</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Hover; Multiple Selection – Fill – Active/Selected Hover
                  </WppTypography>{' '}
                  <br />
                  <WppTypography type="s-strong">Inline Message:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Warning, Informational – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">App Top Bar:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Menu Item – Unselected Hover/Active</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Menu Sub-item – Unselected Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">App Sidebar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Menu Item, Sub-item – Unselected Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Step Control – Fill – Unselected Hover;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Sub-step Control – Fill – Unselected Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Page Item – Fill – Unselected Hover;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Text Select – Fill – Hover, Active, Active Hover</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Filter Button, List Item, Date Picker, Radio Button, Checkbox, Pills, File Upload…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.300_color" title="Grey-300">
                <div>
                  <WppTypography type="s-strong">Used mainly for Pressed states.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Divider:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Data Table:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Cell – Divider – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Context Menu Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body"> Fill – Hover, Expanded</WppTypography> <br />
                  <WppTypography type="s-strong">Tree Item:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Fill – Pressed; Multiple Selection – Fill – Selected Pressed
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Progress Indicator:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Base Empty – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">App Top Bar:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Menu Item – Fill – Unselected Pressed, Selected;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Menu Sub-item – Fill – Unselected Pressed, Selected</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">App Sidebar:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Menu Item, Sub-item – Unselected Pressed, Selected Idle</WppTypography>
                  <br />
                  <WppTypography type="s-strong">Horizontal Stepper:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Step Control – More Indicator – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Vertical Stepper:&nbsp;</WppTypography>
                  <ul style={{ listStyle: 'inherit', marginLeft: '20px' }}>
                    <li>
                      <WppTypography type="s-body">Step Control – Fill – Unselected Pressed;</WppTypography>
                    </li>
                    <li>
                      <WppTypography type="s-body">Sub-step Control – Fill – Unselected Pressed</WppTypography>
                    </li>
                  </ul>
                  <WppTypography type="s-strong">Pagination:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Page Item – Fill – Unselected Pressed; Text Select – Fill – Pressed
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Input:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Counter – Left/Right Control – Fill – Pressed/Active</WppTypography>{' '}
                  <br />
                  <WppTypography type="s-strong">Segmented Control:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Background – Fill; Parts – Fill – Idle, Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Slider:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Track, Tick Mark – Fill – Idle</WppTypography> <br />
                  <WppTypography type="s-strong">Selectable Cards:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Disabled Not Selected</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Skeleton, List Item, Date Picker Parts, Radio Button, Checkbox, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.400_color" title="Grey-400">
                <div>
                  <WppTypography type="s-strong">Used mainly for Icons, Borders in Disabled state.</WppTypography>{' '}
                  <br />
                  <br />
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon, Border – Disabled</WppTypography> <br />
                  <WppTypography type="s-strong">Data Table:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Column Header – Divider – Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Stepper:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Step Indicator – Next – Border; Divider – Fill; Sub-step Indicator – Border
                  </WppTypography>
                  <br />
                  <WppTypography type="s-strong">Inputs:&nbsp;</WppTypography>
                  <WppTypography type="s-body">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills, Scroll…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.500_color" title="Grey-500">
                <WppTypography type="s-strong">Used mainly for Label/Text in Disabled state.</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.600_color" title="Grey-600">
                <div>
                  <WppTypography type="s-strong">Used mainly for Borders, Icons in Idle state.</WppTypography> <br />{' '}
                  <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Idle</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Filled, Hint</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.700_color" title="Grey-700">
                <div>
                  <WppTypography type="s-strong">Used mainly for Borders in Hover state.</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">Hint Text</WppTypography>
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill (12%) – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Hover</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Hover</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.800_color" title="Grey-800">
                <div>
                  <WppTypography type="s-strong">
                    Used mainly for Borders in Pressed state; Action Icons in Hover state.
                  </WppTypography>
                  <br /> <br />
                  <WppTypography type="s-strong">Muted, Supplementary Text</WppTypography>
                  <WppTypography type="s-strong">Action Secondary Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Icon – Idle, Hover; Fill (18%) – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border, Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Quiet Link:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Info – Label</WppTypography> <br />
                  <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Border – Pressed, Active; Helper Text</WppTypography> <br />
                  <WppTypography type="s-strong">Banner:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Info – Fill</WppTypography> <br /> <br />
                  <WppTypography type="s-strong">
                    Radio Button, Checkbox, Toggle, Segmented Control, Pills…
                  </WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.900_color" title="Grey-900">
                <div>
                  <WppTypography type="s-strong">Used mainly for Action Icons in Pressed/Active states.</WppTypography>{' '}
                  <br /> <br />
                  <WppTypography type="s-strong">Secondary Action Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text – Idle, Hover; Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Filter Button:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Text – Idle, Hover; Icon – Pressed</WppTypography> <br />
                  <WppTypography type="s-strong">Toast:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography> <br />
                  <WppTypography type="s-strong">Tooltip:&nbsp;</WppTypography>
                  <WppTypography type="s-body">Fill</WppTypography>
                </div>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.grey.warm.1000_color" title="Grey-1000">
                <WppTypography type="s-strong">Paragraph Text, Headings, Labels</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'warm',
      },
    ],
    fields: [],
  },
  {
    title: PALETTE_TITLES.DATA_VIZ_CATEGORICAL,
    description:
      'Categorical palette is used for Data Visualisation and some UI components.<br>Use <a href="https://tailwind.ink" style="text-decoration: underline;">https://tailwind.ink</a> and generate 9 categorical colours in 3 levels of contrast.',
    fieldPath: 'color.dataviz.cat',
    sections: [
      {
        name: 'Dark',
        value: 'dark',
        fields: [
          createPaletteField('Dark-1', 1, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.1_color" title="Dark-1">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-2', 2, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.2_color" title="Dark-2">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-3', 3, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.3_color" title="Dark-3">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-4', 4, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.4_color" title="Dark-4">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-5', 5, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.5_color" title="Dark-5">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-6', 6, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.6_color" title="Dark-6">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-7', 7, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.7_color" title="Dark-7">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-8', 8, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.8_color" title="Dark-8">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-9', 9, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.9_color" title="Dark-9">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Dark-10', 10, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.dark.10_color" title="Dark-10">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'dark',
      },
      {
        name: 'Neutral',
        value: 'neutral',
        fields: [
          createPaletteField('Neutral-1', 1, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.1_color" title="Neutral-1">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-2', 2, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.2_color" title="Neutral-2">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-3', 3, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.3_color" title="Neutral-3">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-4', 4, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.4_color" title="Neutral-4">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-5', 5, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.5_color" title="Neutral-5">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-6', 6, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.6_color" title="Neutral-6">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-7', 7, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.7_color" title="Neutral-7">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-8', 8, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.8_color" title="Neutral-8">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-9', 9, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.9_color" title="Neutral-9">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Neutral-10', 10, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.neutral.10_color" title="Neutral-10">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'neutral',
      },
      {
        name: 'Light',
        value: 'light',
        fields: [
          createPaletteField('Light-1', 1, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.1_color" title="Light-1">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-2', 2, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.2_color" title="Light-2">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-3', 3, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.3_color" title="Light-3">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-4', 4, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.4_color" title="Light-4">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-5', 5, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.5_color" title="Light-5">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-6', 6, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.6_color" title="Light-6">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-7', 7, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.7_color" title="Light-7">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-8', 8, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.8_color" title="Light-8">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-9', 9, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.9_color" title="Light-9">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Light-10', 10, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.cat.light.10_color" title="Light-10">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'light',
      },
    ],
    fields: [],
  },
  {
    title: PALETTE_TITLES.DATA_VIZ_SEQUENTIAL,
    description: 'Sequential palette is used for Data Visualisation and deliver colours to the System palette.',
    fieldPath: 'color.dataviz.seq',
    sections: [
      {
        name: 'Brand',
        value: 'brand',
        fields: [
          createPaletteField('Brand-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.100_color" title="Brand-100">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.200_color" title="Brand-200">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.300_color" title="Brand-300">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.400_color" title="Brand-400">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.500_color" title="Brand-500">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.600_color" title="Brand-600">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.700_color" title="Brand-700">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.800_color" title="Brand-800">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.900_color" title="Brand-900">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Brand-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.brand.1000_color" title="Brand-1000">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'brand',
      },
      {
        name: 'Grey',
        value: 'grey',
        fields: [
          createPaletteField('Grey-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.100_color" title="Grey-100">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.200_color" title="Grey-200">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.300_color" title="Grey-300">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.400_color" title="Grey-400">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.500_color" title="Grey-5">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.600_color" title="Grey-600">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.700_color" title="Grey-700">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.800_color" title="Grey-800">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.900_color" title="Grey-900">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Grey-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.grey.1000_color" title="Grey-1000">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'grey',
      },
      {
        name: 'Negative',
        value: 'negative',
        fields: [
          createPaletteField('Negative-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.100_color" title="Negative-100">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.200_color" title="Negative-200">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.300_color" title="Negative-300">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.400_color" title="Negative-400">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.500_color" title="Negative-500">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.600_color" title="Negative-600">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.700_color" title="Negative-700">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.800_color" title="Negative-800">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.900_color" title="Negative-900">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Negative-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.negative.1000_color" title="Negative-1000">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'negative',
      },
      {
        name: 'Warning',
        value: 'warning',
        fields: [
          createPaletteField('Warning-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.100_color" title="Warning-100">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.200_color" title="Warning-200">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.300_color" title="Warning-300">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.400_color" title="Warning-400">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.500_color" title="Warning-500">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.600_color" title="Warning-600">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.700_color" title="Warning-700">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.800_color" title="Warning-800">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.900_color" title="Warning-900">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Warning-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.warning.1000_color" title="Warning-1000">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'warning',
      },
      {
        name: 'Positive',
        value: 'positive',
        fields: [
          createPaletteField('Positive-100', 100, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.100_color" title="Positive-100">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-200', 200, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.200_color" title="Positive-200">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-300', 300, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.300_color" title="Positive-300">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-400', 400, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.400_color" title="Positive-400">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-500', 500, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.500_color" title="Positive-500">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-600', 600, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.600_color" title="Positive-600">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-700', 700, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.700_color" title="Positive-700">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-800', 800, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.800_color" title="Positive-800">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-900', 900, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.900_color" title="Positive-900">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
          createPaletteField('Positive-1000', 1000, '', {
            content: (
              <ColorsUsageDetails path="color.dataviz.seq.positive.1000_color" title="Positive-1000">
                <WppTypography type="s-strong">-</WppTypography>
              </ColorsUsageDetails>
            ),
          }),
        ],
        fieldPath: 'positive',
      },
    ],
    fields: [],
  },
  {
    title: 'System Palette',
    description:
      'System colours are delivered from the DataViz Sequential palette. Choose colours that match with recommended contrast ratios.',
    fieldPath: 'color.system',
    fields: [
      createPaletteField('Danger-200', 'danger.200', '', {
        content: (
          <ColorsUsageDetails path="color.system.danger.200_color" title="Danger-200">
            <div>
              <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
              <WppTypography type="s-body">Negative – Background Fill (50%)</WppTypography> <br />
              <WppTypography type="s-strong">Inline Message:&nbsp;</WppTypography>
              <WppTypography type="s-body">M Size – Error – Fill</WppTypography> <br />
              <WppTypography type="s-strong">Tooltip</WppTypography>
              <WppTypography type="s-body">Validation – Fill</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Danger-300', 'danger.300', '', {
        content: (
          <ColorsUsageDetails path="color.system.danger.300_color" title="Danger-300">
            <div>
              <WppTypography type="s-strong">Regular Destructive Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Disabled</WppTypography> <br />
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Danger-400', 'danger.400', '', {
        content: (
          <ColorsUsageDetails path="color.system.danger.400_color" title="Danger-400">
            <div>
              <WppTypography type="s-strong">Error Icon</WppTypography> <br />
              <WppTypography type="s-strong">Regular Destructive Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Table Cell</WppTypography>
              <WppTypography type="s-body">Error Row Indicator</WppTypography> <br />
              <WppTypography type="s-strong">Inputs, Selects</WppTypography>
              <WppTypography type="s-body">Border – Error – Idle</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Danger-500', 'danger.500', '', {
        content: (
          <ColorsUsageDetails path="color.system.danger.500_color" title="Danger-500">
            <div>
              <WppTypography type="s-strong">Error Labels, Text</WppTypography> <br />
              <WppTypography type="s-strong">Regular Destructive Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Idle</WppTypography> <br />
              <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
              <WppTypography type="s-body">Negative – Label Text, Icon</WppTypography> <br />
              <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
              <WppTypography type="s-body">Error Message; Border – Error – Hover</WppTypography> <br />
              <WppTypography type="s-strong">File Upload:&nbsp;</WppTypography>
              <WppTypography type="s-body">Upload Area – Border – Error</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Danger-600', 'danger.600', '', {
        content: (
          <ColorsUsageDetails path="color.system.danger.600_color" title="Danger-600">
            <div>
              <WppTypography type="s-strong">Regular Destructive Button:&nbsp;</WppTypography>
              <WppTypography type="s-body">Fill – Pressed</WppTypography> <br />
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Warning-200', 'warning.200', '', {
        content: (
          <ColorsUsageDetails path="color.system.warning.200_color" title="Warning-200">
            <div>
              <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
              <WppTypography type="s-body">Warning – Background Fill (50%)</WppTypography> <br />
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Warning-400', 'warning.400', '', {
        content: (
          <ColorsUsageDetails path="color.system.warning.400_color" title="Warning-400">
            <div>
              <WppTypography type="s-strong">Warning Icon</WppTypography> <br />
              <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
              <WppTypography type="s-body">Border – Warning – Idle</WppTypography> <br />
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Warning-500', 'warning.500', '', {
        content: (
          <ColorsUsageDetails path="color.system.warning.500_color" title="Warning-500">
            <div>
              <WppTypography type="s-strong">Inputs, Selects:&nbsp;</WppTypography>
              <WppTypography type="s-body">Warning Message; Border – Warning – Hover</WppTypography> <br />
              <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
              <WppTypography type="s-body">Warning – Label Text, Icon</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Success-200', 'success.200', '', {
        content: (
          <ColorsUsageDetails path="color.system.success.200_color" title="Success-200">
            <div>
              <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
              <WppTypography type="s-body">Success – Background Fill (50%)</WppTypography> <br />
              <WppTypography type="s-strong">Inline Message:&nbsp;</WppTypography>
              <WppTypography type="s-body">M Size – Success – Fill</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Success-400', 'success.400', '', {
        content: (
          <ColorsUsageDetails path="color.system.success.400_color" title="Success-400">
            <div>
              {' '}
              <WppTypography type="s-strong">Success Icon</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Success-500', 'success.500', '', {
        content: (
          <ColorsUsageDetails path="color.system.success.500_color" title="Success-500">
            <div>
              <WppTypography type="s-strong">Tag:&nbsp;</WppTypography>
              <WppTypography type="s-body">Success – Label Text, Icon</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Highlight-200', 'highlight.200', '', {
        content: (
          <ColorsUsageDetails path="color.system.highlight.200_color" title="Highlight-200">
            <WppTypography type="s-strong">-</WppTypography>
          </ColorsUsageDetails>
        ),
      }),
      createPaletteField('Highlight-400', 'highlight.400', '', {
        content: (
          <ColorsUsageDetails path="color.system.highlight.400_color" title="Highlight-400">
            <div>
              <WppTypography type="s-strong">Banner:&nbsp;</WppTypography>
              <WppTypography type="s-body">Highlight – Fill</WppTypography>
            </div>
          </ColorsUsageDetails>
        ),
      }),
    ],
    sections: [],
  },
]

export const TYPOGRAPHY_TYPES_SECTIONS: TypographyTypeSection[] = [
  {
    title: '5XL – Display',
    fieldPath: 'typography.5xl',
    sections: [
      {
        fontPreview: {
          text: 'Display text',
          type: '5xl-display',
        },
        fieldPath: 'display',
        canHaveSecondaryFont: true,
      },
    ],
    usageDetails: {
      content: (
        <TypographyUsageDetails title="5XL-Display" previewText="Display text" type="5xl-display">
          <WppTypography type="s-body">
            Page heading;
            <br />
            Hero Section heading.
          </WppTypography>
        </TypographyUsageDetails>
      ),
    },
  },
  {
    title: '4XL – Display',
    fieldPath: 'typography.4xl',
    sections: [
      {
        fontPreview: {
          text: 'Display text',
          type: '4xl-display',
        },
        fieldPath: 'display',
        canHaveSecondaryFont: true,
      },
    ],
    usageDetails: {
      content: (
        <TypographyUsageDetails title="4XL-Display" previewText="Display text" type="4xl-display">
          <WppTypography type="s-body">
            Page heading
            <br />
            Section heading
          </WppTypography>
        </TypographyUsageDetails>
      ),
    },
  },
  {
    title: '3XL – Heading',
    fieldPath: 'typography.3xl',
    sections: [
      {
        fontPreview: {
          text: 'Heading text',
          type: '3xl-heading',
        },
        fieldPath: 'heading',
        canHaveSecondaryFont: true,
      },
    ],
    usageDetails: {
      content: (
        <TypographyUsageDetails title="3XL-Heading" previewText="Preview heading" type="3xl-heading">
          <WppTypography type="s-body">
            Page heading
            <br />
            Navigation header:Page title
          </WppTypography>
        </TypographyUsageDetails>
      ),
    },
  },
  {
    title: '2XL – Heading',
    fieldPath: 'typography.2xl',
    sections: [
      {
        fontPreview: {
          text: 'Heading text',
          type: '2xl-heading',
        },
        fieldPath: 'heading',
      },
    ],
    usageDetails: {
      content: (
        <TypographyUsageDetails title="2XL-Heading" previewText="Preview heading" type="2xl-heading">
          <WppTypography type="s-body">
            Cards: 2XL size – Title
            <br />
            Side Modal: Title
            <br />
            Accordion: 2XL size – Section title
            <br />
            Avatar: 2XL size – Text content
            <br />
            Statistic: 2XL size – Text content
          </WppTypography>
        </TypographyUsageDetails>
      ),
    },
  },
  {
    title: 'XL – Heading',
    fieldPath: 'typography.xl',
    sections: [
      {
        fontPreview: {
          text: 'Heading text',
          type: 'xl-heading',
        },
        fieldPath: 'heading',
      },
    ],
    usageDetails: {
      content: (
        <TypographyUsageDetails title="XL-Heading" previewText="Preview heading" type="xl-heading">
          <WppTypography type="s-body">
            Cards: XL size – Title
            <br />
            Modal: Title
            <br />
            Accordion: XL size – Section title
            <br />
            Statistic: XL size – Text content
          </WppTypography>
        </TypographyUsageDetails>
      ),
    },
  },
  {
    title: 'L – Body, Midi, Strong',
    fieldPath: 'typography.l',
    sections: [
      {
        fontPreview: {
          text: 'Body text',
          type: 'l-body',
        },
        fieldPath: 'body',
      },
      {
        fontPreview: {
          text: 'Midi text',
          type: 'l-midi',
        },
        fieldPath: 'midi',
      },
      {
        fontPreview: {
          text: 'Strong text',
          type: 'l-strong',
        },
        fieldPath: 'strong',
      },
    ],
    usageDetails: {
      content: (
        <div>
          <TypographyUsageDetails
            title="L-Strong"
            previewText="Preview text"
            type="l-strong"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">
              Cards: L size – Title
              <br />
              Accordion: L size – Section title
              <br />
              Avatar: L size – Text content
              <br />
              Statistic: L size – Text content
            </WppTypography>
          </TypographyUsageDetails>

          <TypographyUsageDetails
            title="L-Midi"
            previewText="Preview text"
            type="l-midi"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">Highlighting a text in a paragraph</WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails title="L-Body" previewText="Preview text" type="l-body">
            <WppTypography type="s-body">Paragraph body</WppTypography>
          </TypographyUsageDetails>
        </div>
      ),
    },
  },
  {
    title: 'M – Body, Midi, Strong',
    fieldPath: 'typography.m',
    sections: [
      {
        fontPreview: {
          text: 'Body text',
          type: 'm-body',
        },
        fieldPath: 'body',
      },
      {
        fontPreview: {
          text: 'Midi text',
          type: 'm-midi',
        },
        fieldPath: 'midi',
      },
      {
        fontPreview: {
          text: 'Strong text',
          type: 'm-strong',
        },
        fieldPath: 'strong',
      },
    ],
    usageDetails: {
      content: (
        <div>
          <TypographyUsageDetails
            title="M-Strong"
            previewText="Preview text"
            type="m-strong"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">
              Highlighting an important info in M body
              <br />
              Accordion: M size – Section title
              <br />
              Statistic: M size – Text content
              <br />
              Tooltip: Title
              <br />
              Card: M size – Title
            </WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails
            title="M-Midi"
            previewText="Preview text"
            type="m-midi"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">Highlighting a text in a paragraph</WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails title="M-Body" previewText="Preview text" type="m-body">
            <WppTypography type="s-body">Paragraph body</WppTypography>
          </TypographyUsageDetails>
        </div>
      ),
    },
  },
  {
    title: 'S – Body, Midi, Strong',
    fieldPath: 'typography.s',
    sections: [
      {
        fontPreview: {
          text: 'Body text',
          type: 's-body',
        },
        fieldPath: 'body',
      },
      {
        fontPreview: {
          text: 'Midi text',
          type: 's-midi',
        },
        fieldPath: 'midi',
      },
      {
        fontPreview: {
          text: 'Strong text',
          type: 's-strong',
        },
        fieldPath: 'strong',
      },
    ],
    usageDetails: {
      content: (
        <div>
          <TypographyUsageDetails
            title="S-Strong"
            previewText="Preview text"
            type="s-strong"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">
              Compact paragraph body <br />
              Input <br />
              Combined input <br />
              Date picker <br />
              Search: Text <br />
              Text area <br />
              Counter <br />
              Select <br />
              Autocomplete <br />
              Checkbox / Toggle / Radio Button: Label <br />
              Context Menu Item <br />
              Side bar: Menu sub-item <br />
              Drop-down: Unselected list item <br />
              Tag <br />
              Segmented Control: Counter <br />
              Table: Cell text <br />
              Tree: Item <br />
              List: item <br />
              Inline message: M size – Text <br />
              Toast: message <br />
              Tooltip: Text, Label <br />
              Breadcrumbs: Text <br />
              Stepper Bar: Unselected step name <br />
              Pagination: Items counter, unselected page number <br />
              Pill: item text <br />
              File upload: Text, item name <br />
              Modal: Message <br />
            </WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails
            title="S-Midi"
            previewText="Preview text"
            type="s-midi"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">
              Segmented control: Text <br />
              Sidebar: Menu item <br />
              Top bar: Menu item <br />
              Tooltip Value: Text <br />
              Tree: Selected Item <br />
              Banner: Text <br />
              Tag <br />
              Statistic: Label <br />
              List: Selected item <br />
              Progress Indicator: Value <br />
              Tooltip: Value <br />
              Stepper Bar: Selected step name
            </WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails title="S-Body" previewText="Preview text" type="s-body">
            <WppTypography type="s-body">
              Button text <br />
              Statistic: S size – Text content <br />
              Labels: Inputs, Select <br />
              List Item: Selected <br />
              Tabs <br />
              Highlight important info in S body <br />
              Avatar: M size – Text content <br />
              Accordion: S size – Section title <br />
              Table: Column header <br />
              Axis: Label <br />
              Pagination: Selected page number <br />
              Pill: Selected item text <br />
              Card: S size – Title
            </WppTypography>
          </TypographyUsageDetails>
        </div>
      ),
    },
  },
  {
    title: 'XS – Body, Midi, Strong',
    fieldPath: 'typography.xs',
    sections: [
      {
        fontPreview: {
          text: 'Body text',
          type: 'xs-body',
        },
        fieldPath: 'body',
      },
      {
        fontPreview: {
          text: 'Midi text',
          type: 'xs-midi',
        },
        fieldPath: 'midi',
      },
      {
        fontPreview: {
          text: 'Strong text',
          type: 'xs-strong',
        },
        fieldPath: 'strong',
      },
    ],
    usageDetails: {
      content: (
        <div>
          <TypographyUsageDetails
            title="XS-Strong"
            previewText="Preview text"
            type="xs-strong"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">
              Supplementary text <br />
              Drop-down Menu Item: Sub Item <br />
              Table: Cell sub-text <br />
              Legend: Text <br />
              Axis: Text <br />
              Slider: Captions text <br />
              File upload: File format text <br />
            </WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails
            title="XS-Midi"
            previewText="Preview text"
            type="xs-midi"
            style={{ marginBottom: '48px' }}
          >
            <WppTypography type="s-body">
              Highlighting a text in a paragraph <br />
              Data Zoom: Range text
            </WppTypography>
          </TypographyUsageDetails>
          <TypographyUsageDetails title="XS-Body" previewText="Preview text" type="xs-body">
            <WppTypography type="s-body">
              Highlight important info in XS body <br />
              Inline message: S size text <br />
              Stepper Bar: Step number
            </WppTypography>
          </TypographyUsageDetails>
        </div>
      ),
    },
  },
  {
    title: '2XS – Strong Uppercase',
    fieldPath: 'typography.2xs',
    sections: [
      {
        fontPreview: {
          text: 'Strong text',
          type: '2xs-strong',
        },
        fieldPath: 'strong',
      },
    ],
    usageDetails: {
      content: (
        <TypographyUsageDetails title="2XS-Strong" previewText="Preview Body" type="2xs-strong">
          <WppTypography type="s-body">Avatar: S, XS sizes – Text content</WppTypography>
        </TypographyUsageDetails>
      ),
    },
  },
]
