// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ufUfJ{display:flex;flex-direction:column}.ufUfJ .wc3_3{height:114px;margin:8px 0 20px 0}.ufUfJ .wc3_3::part(card){display:flex;align-items:center;width:376px}.ufUfJ .HXBOg{margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/Typography/components/TypographyUsageDetails/TypographyUsageDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CAEA,cACE,YAAA,CACA,mBAAA,CAEA,0BACE,YAAA,CACA,kBAAA,CACA,WAAA,CAIJ,cACE,kBAAA","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n\n  .card {\n    height: 114px;\n    margin: 8px 0 20px 0;\n\n    &::part(card) {\n      display: flex;\n      align-items: center;\n      width: 376px;\n    }\n  }\n\n  .divider {\n    margin-bottom: 20px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `ufUfJ`,
	"card": `wc3_3`,
	"divider": `HXBOg`
};
export default ___CSS_LOADER_EXPORT___;
