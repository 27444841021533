// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y9vR8{background-color:var(--wpp-grey-color-100);min-height:calc(100vh - var(--wpp-os-header-height))}.NPCWo{display:flex;justify-content:center;margin:0 auto}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,0CAAA,CACA,oDAAA,CAGF,OACE,YAAA,CACA,sBAAA,CACA,aAAA","sourcesContent":[".container {\n  background-color: var(--wpp-grey-color-100);\n  min-height: calc(100vh - var(--wpp-os-header-height));\n}\n\n.pageContent {\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Y9vR8`,
	"pageContent": `NPCWo`
};
export default ___CSS_LOADER_EXPORT___;
